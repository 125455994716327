import { VideoTypeEnum } from '@/lib/types/api/video';
import { FranchiseImages } from '@/lib/types/api/franchise-data';
import { ShopLinks } from "@/lib/types/api/shop-data";
import { Sponsor } from "@/components/SponsorshipRow/SponsorshipRow";

export interface ShowData {
    available_in_region:   boolean;
    content_strip_data:    ContentStripData;
    featured_content:      FeaturedContent;
    featured_videos:       FeaturedVideos;
    ga_events:             object;
    key_art:               string;
    photo_gallery:         PhotoGallery;
    shop_links:            ShopLinks;
    show_airdates:         ShowAirdates;
    show_details:          ShowDetails;
    show_meta_description: string;
    show_row:              ShowRow;
    supporting_text:       SupportingText;
    supporting_links:      SupportingLinks;
    learning_media_url:    null;
    sponsor_info_link:     null;
    seasons:               any;
}

export interface ContentStripData {
    episodes_data:    EpisodesData;
    specials_data:    SpecialsData;
    extras_data:      ExtrasData;
    collections_data: CollectionsData;
    has_episodes:     boolean;
    has_specials:     boolean;
    has_extras:       boolean;
    has_collections:  boolean;
}

export interface CollectionsData {
    has_collections: boolean;
    content:         Collection[];
    content_type:    string;
    title:           string;
    title_link:      string;
}

export interface Collection {
  title: string;
  slug: string;
  show: {
    slug: string;
    title: string;
  };
  description_short: string;
  description_long: string;
  image: string;
}
export interface EpisodesData {
    seasons:                 Season[];
    default_season:          number;
    default_season_episodes: DefaultS;
    has_episodes:            boolean;
    content:                 DefaultSeasonEpisodesContent[];
}

export interface DefaultS {
    meta:    Meta;
    content: DefaultSeasonEpisodesContent[];
}

export interface DefaultSeasonEpisodesContent {
    slug:              string;
    title:             string;
    description_short: string;
    video_type:        VideoTypeEnum;
    flags:             Flags;
    summary:           string;
    ancestor_title:    Title;
    ancestor_slug:     Slug;
    ancestor_type:     ShowOrFranchiseEnum;
    image:             string;
    cid:               string;
}

export enum Slug {
    MarieAntoinette = "marie-antoinette",
}

export enum Title {
    MarieAntoinette = "Marie Antoinette",
}

export enum ShowOrFranchiseEnum {
    Show = "show",
    Franchise = "franchise",
}

export interface Flags {
    is_new:           boolean;
    is_mvod:          boolean;
    has_captions:     boolean;
    is_expiring_soon: boolean;
    is_fully_watched: null;
}


export interface Meta {
    links:    Links;
    duration: string;
}

export interface Links {
    self: string;
    prev: null;
    next: null | string;
}

export interface Season {
    cid:     string;
    ordinal: number;
}

export interface ExtrasData {
    seasons:               Season[];
    default_season:        number;
    default_season_extras: DefaultS;
    has_extras:            boolean;
    title:                 string;
    title_link:            string;
}

export interface SpecialsData {
    default_specials: DefaultS;
    has_specials:     boolean;
}

export interface FeaturedContent {
    url:         string;
    link:        FeaturedContentLink;
    type:        string;
    image:       string;
    title:       string;
    description: string;
}

export interface FeaturedContentLink {
    url:   string;
    title: string;
}

export interface FeaturedVideos {
    content:             FeaturedVideosContent[];
    picture_breakpoints: PictureBreakpoints;
}

export interface FeaturedVideosContent {
    slug:               string;
    title:              string;
    title_sortable:     string;
    duration:           number;
    description_short:  string;
    description_long:   string;
    item_type:          string;
    video_type:         VideoTypeEnum;
    premiere_date:      Date;
    encore_date:        Date;
    url:                string;
    images:             ContentImages;
    flags:              Flags;
    availability:       string;
    seconds_watched:    null;
    expire_date:        Date;
    audio:              Audio[];
    parent_type:        VideoTypeEnum;
    show:               Show;
    summary:            string;
    ancestor_title:     Title;
    ancestor_slug:      Slug;
    ancestor_type:      ShowOrFranchiseEnum;
    image:              string;
    legacy_tp_media_id: number;
    cid:                string;
}

export interface Audio {
    channel:     string;
    language:    string;
    descriptive: boolean;
}

export interface ContentImages {
    "asset-mezzanine-16x9": string;
}

export interface Show {
    slug:                   Slug;
    title:                  Title;
    season:                 number;
    episode:                number;
    seasons_count:          number;
    display_episode_number: boolean;
}

export interface PictureBreakpoints {
    hero:                    FeaturedVideo[];
    hero_default:            string;
    featured_videos:         FeaturedVideo[];
    featured_videos_default: string;
}

export interface FeaturedVideo {
    mq:   string;
    "1x": string;
    "2x": string;
}

export interface SupportingLink {
    url?: string;
    image?: string;
    title?: string;
}

export interface SupportingLinks {
    title: string;
    content: SupportingLink[];
    className?: string;
}

export interface PhotoGallery {
    title:   string;
    content: PhotoGalleryContent[];
}

export interface PhotoGalleryContent {
    alt_text:     string;
    image_url:    string;
    credit_text:  string;
    caption_text: string;
}

export interface ShowAirdates {
    content: any[];
}

export interface ShowDetails {
    cid:                    string;
    url?:                    string;
    slug:                   string;
    title:                  string;
    title_sortable?:         string;
    nola_root?:              string;
    description_short:      string;
    tracking_ga_page?:       string;
    tracking_ga_event?:      string;
    links?:                  LinkElement[];
    seasons_count?:          number;
    description_long?:       string;
    countries?:              string[];
    audience?:               Audience[];
    genre?:                  Genre;
    images?:                 ShowDetailsImages;
    image?:                  string;
    hashtag?:                string;
    funder_message?:         string;
    premiere_date?:          Date;
    popularity?:            string | boolean | number | null;
    popularity_position?:   number | null;
    franchise?:              {
      cid: string;
      slug: string;
      title: string;
      url: string;
      images?: FranchiseImages;
      description_long?: string;
      logo?: string;
      logo_white?: string;
      image?: string;
    } | null;
    item_type:              'show';
    broadcast_info?:         string;
    funder_information?:     string;
    logo_white?:             string;
    logo_color?:             string;
    logo_black?:             string;
    poster?:                 string;
    social_links?:           any[];
    website?:                string;
    sponsor_logos?:          Sponsor[];
    test_data?:              boolean;
    local_content_stations?: any[];
    is_kids?:                boolean;
}

export interface Audience {
    scope:   string;
    station: null | {
        cid: string;
        call_sign: string;
        short_common_name: string;
    };
}

export interface Genre {
    cid:   string;
    slug:  string;
    title: string;
    url:   string;
}

export interface ShowDetailsImages {
    "show-mezzanine16x9"?:          string;
    "show-banner"?:                string;
    "show-poster2x3":              string;
    "white-logo-41"?:              string;
    "color-logo-41"?:              string;
    background?:                   string;
    "black-logo-41"?:              string;
    "show-logo"?:                  string;
    "show-showcase"?:              string;
    "show-white-logo"?:            string;
    "show-black-logo"?:            string;
    "show-color-logo"?:            string;
    "asset-kids-mezzanine1-16x9"?: string;
}

export interface LinkElement {
    value:      string;
    profile:    string;
    updated_at: string;
}

export interface ShowRow {
    title:      string;
    title_link: string;
    content:    ShowRowContent[];
}

export interface ShowRowContent {
    description_long?:    string;
    description_short?:   string;
    images:             ShowDetailsImages;
    links:               LinkElement[];
    slug:                string;
    title_sortable:      string;
    title:               string;
    tracking_ga_event:   string;
    tracking_ga_page:    string;
    item_type:           ShowOrFranchiseEnum;
    cid:                 string;
    franchise:           Genre | null;
    genre:               Genre;
    countries?:           string[];
    audience:            Audience[];
    url:                 string;
    popularity:          null;
    popularity_position: number | null;
    image?:              string;
}

export interface SupportingText {
    title:          string;
    text:           string;
    text_secondary: string;
    className?:     string;
}

export interface FeaturedPreview {
    cid: string;
    slug: string;
    title: string;
    videoType: 'CLIP' | 'PREVIEW' | 'FULL_LENGTH';
    mezzanine16x9ImageUrl: string;
    availability: string;
}

export interface ShowFeaturedPreviewResponse {
    data: {
      showContext: {
        featuredPreview: FeaturedPreview
      }
    }
}

export interface ShowCollectionData {
    cid:                string;
    slug:               string;
    title:              string;
    title_sortable:     string;
    flags:              Flags;
    show:               Show;
    description_short:  string;
    description_long:   string;
    featured:           boolean;
    images:             ContentImages;
    item_type:          string;
}