import { ReactNode } from "react";
import IconButton from "@/components/Button/IconButton";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useState } from "react";
import styles from './KabobMenu.module.scss';
interface KabobMenuProps {
  children: ReactNode;
  className?: string;
  contentClassName?: string;
}
const KabobMenu = (props: KabobMenuProps) => {
  const {
    className,
    contentClassName
  } = props;
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  let classes = styles.kabob_menu;
  if (className) {
    classes += ' ' + className;
  }
  let contentClasses = styles.kabob_menu_content;
  if (contentClassName) {
    contentClasses += ' ' + contentClassName;
  }
  const PopoverMenu = <div className={contentClasses}>
      <IconButton onClick={visible ? hide : show} className={styles.kabob_menu_close_button} icon="close" title="Close" />
      {props.children}
    </div>;
  return <Tippy content={PopoverMenu} arrow={true} interactive={true} placement="auto-end" role="popover" theme="charcoal" className={styles.kabob_menu_tippy} offset={[0, 0]} visible={visible} onClickOutside={hide} data-sentry-element="Tippy" data-sentry-component="KabobMenu" data-sentry-source-file="KabobMenu.tsx">
      <div className={classes}>
        <IconButton icon="kabob" title="Toggle Menu" className={styles.kabob_menu_button} onClick={visible ? hide : show} data-sentry-element="IconButton" data-sentry-source-file="KabobMenu.tsx" />
      </div>
    </Tippy>;
};
export default KabobMenu;