"use client";

// imports
import Link from 'next/link';
import { useState } from 'react';

// lib files
import { VideoClass } from '@/lib/types/api/video';

// components
import KabobMenu from '@/components/KabobMenu/KabobMenu';
import LinkButton from '@/components/Button/LinkButton';
import MezzanineVideoInitializer from '@/components/MezzanineVideoInitializer/MezzanineVideoInitializer';
import MyListButton from '@/components/Button/MyList/MyListButton';
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer";

// svgs
import PlayIcon from "@/public/svg/play.svg";

// styles
import styles from './Highlight.module.scss';
export interface VideoHighlightProps {
  video: VideoClass;
}
export default function VideoHighlight(props: VideoHighlightProps) {
  const {
    video
  } = props;
  const [showVideo, setShowVideo] = useState(false);
  const playerConfig = {
    autoplay: true,
    muted: true,
    disableContinuousPlay: true
  };
  let descriptionStyles = styles.highlight_block__description;
  if (showVideo) {
    descriptionStyles += ` ${styles.highlight__video_playing}`;
  }
  return <div className={styles.highlight} data-sentry-component="VideoHighlight" data-sentry-source-file="VideoHighlight.tsx">
      <div className={styles.highlight_poster}>
         {/* @TODO insert a video player when we have related assets  */}

        {!showVideo &&
      // @TODO let these play a *related asset* when clicked
      <MezzanineVideoInitializer imgSrc={video.image} onClick={() => {}} alt={video.title} videoType={video.video_type} verticalOffsetButton={false} showWatchButton={false} width={860} />}
      </div>

      <div className={descriptionStyles}>
        {/* TODO: Add badging here post MVP when the backend supports it */}
        <h2 className={styles.highlight_first_title}>
          <Link href={`/show/${video.show?.slug}/`} data-sentry-element="Link" data-sentry-source-file="VideoHighlight.tsx">{video.show?.title}</Link>
        </h2>
        <h3 className={styles.highlight_second_title}>
          <Link href={`/video/${video.slug}/`} data-sentry-element="Link" data-sentry-source-file="VideoHighlight.tsx">{video.title}</Link>
        </h3>
        <span className={styles.highlight_text}>
          {video.description_short}
        </span>

        <div className={styles.highlight__controls}>
          <LinkButton href={`/video/${video.slug}/`} style="white" iconBefore="play" className={styles.highlight__controls_button} data-sentry-element="LinkButton" data-sentry-source-file="VideoHighlight.tsx">
            Watch Now
          </LinkButton>
          <KabobMenu className={styles.highlight__controls_kabob} data-sentry-element="KabobMenu" data-sentry-source-file="VideoHighlight.tsx">
            <ul>
              <li>
                <MyListButton style='kabobMenu' video={{
                slug: video.slug,
                cid: video.cid
              }} data-sentry-element="MyListButton" data-sentry-source-file="VideoHighlight.tsx" />
              </li>
              <li>
                <Link href={`/show/${video.show?.slug}/`} className={styles.highlight_explore_the_show} data-sentry-element="Link" data-sentry-source-file="VideoHighlight.tsx">
                    <PlayIcon data-sentry-element="PlayIcon" data-sentry-source-file="VideoHighlight.tsx" />
                    Explore the Show
                </Link>
              </li>
            </ul>
          </KabobMenu>
        </div>
      </div>
    </div>;
}