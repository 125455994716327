import styles from './ContentRowTitleDescription.module.scss';
import ITSImage from '@/components/ITSImage/ITSImage';
interface ContentRowTitleDescriptionProps {
  title: string;
  description?: string;
  logo?: string;
  logo_alt?: string;
}
const ContentRowTitleDescription = (props: ContentRowTitleDescriptionProps) => {
  const {
    title,
    logo,
    logo_alt,
    description
  } = props;
  return <div className={styles.content_row_title_description} data-sentry-component="ContentRowTitleDescription" data-sentry-source-file="ContentRowTitleDescription.tsx">
        <h2 className={styles.content_row_title}>
          {logo && logo_alt && <span className={styles.content_row_title__logo__wrapper}>
              <ITSImage src={logo} alt={logo_alt} width={125} className={styles.content_row_title__logo} />
            </span>}
          {title}
        </h2>

      {description && <p className={styles.content_row_description}>{description}</p>}

    </div>;
};
export default ContentRowTitleDescription;